import {Link} from 'gatsby'
import React, {Component} from 'react'

import styles from './footer.module.scss'

class Footer extends Component {
    render() {
        return (
            <footer className={styles.siteFooter}>
                <nav className={styles.footerNav}>
                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/whatwedo/">Was wir machen</Link>
                        </li>
                        <li>
                            <Link to="/impressum/">Impressum</Link>
                        </li>
                        <li>
                            <Link to="/agb/">AGB</Link>
                        </li>
                    </ul>
                </nav>
            </footer>
        )
    }
}

export default Footer
