import {Link} from 'gatsby'
import React, {Component} from 'react'

import styles from './header.module.scss'
import logoSmallMucSafe from '../../images/Logo_Munich_Safety_72.png';

class Header extends Component {
    render() {
        const ListLink = props => (
            <li>
                <Link to={props.to} activeClassName={styles.activeLink}>
                    {props.children}
                </Link>
            </li>
        )
        return (
            <header className={styles.siteHeader}>
                <nav>
                    <ul>
                        <ListLink to={`/`}>Home</ListLink>
                        <ListLink to={`/whatwedo`}>Was wir machen</ListLink>
                        <ListLink to={`/certifications`}>Zertifizierungen</ListLink>
                    </ul>
                </nav>
                <a href="/"><img src={logoSmallMucSafe} className="logo-img-header" alt="logo-ms-text"/></a>
            </header>
        )
    }
}

export default Header
